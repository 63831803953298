var BaseLifecycle;
(function (BaseLifecycle) {
    BaseLifecycle["onStart"] = "onStart";
    BaseLifecycle["onResume"] = "onResume";
    BaseLifecycle["onPause"] = "onPause";
    BaseLifecycle["onStop"] = "onStop";
    BaseLifecycle["onDestroy"] = "onDestroy";
    BaseLifecycle["onBack"] = "onBack";
    BaseLifecycle["appShow"] = "appShow";
    BaseLifecycle["appHide"] = "appHide";
})(BaseLifecycle || (BaseLifecycle = {}));
export {};
